export const KEY_BENEFITS_SECTION = {
  title: 'Take control of your debt — and your life',
  cta: {
    link: '',
    text: 'See if you are eligible',
  },
  items: [
    {
      id: 'e98fc83a-0d3d-4b9a-bf95-db0c9dbdfca0',
      title: 'Pay less each month',
      description:
        'Free up cash and simplify debts with one affordable monthly payment.',
      icon: 'dollarSignBlue',
    },
    {
      id: '4ac7c400-9109-41cf-91bb-93234ec0581e',
      title: 'Reduce overall debt',
      description:
        'Let our expert negotiators settle your credit card debt for less than you owe.',
      icon: 'trendingDown',
    },
    {
      id: 'dab92d2d-2ee3-4157-a47e-aa745ceef5b0',
      title: 'Resolve debt faster',
      description:
        'Get rid of debt in 24-48 months—faster than minimum payments.',
      icon: 'fastForward',
    },
    {
      id: 'aae72b7e-3a8e-4a5f-8594-9bc6d7b462d2',
      title: 'Reduce debt without a loan',
      description:
        'Simplify and resolve multiple debts instead of getting a consolidation loan.',
      icon: 'checkCircle',
    },
    {
      id: 'd717dd67-2a41-4a1b-b9be-53d81eb2854c',
      title: 'No upfront fees',
      description: "We don't charge fees until you approve a debt settlement.",
      icon: 'dollarSignBlue',
    },
    {
      id: '78377976-6e03-44ec-a3cd-409b340a630e',
      title: 'Program guarantee',
      description:
        "Our program is designed to help you succeed. We're confident you'll get results.",
      icon: 'award',
    },
  ],
}
