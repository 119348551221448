import { useCallback, useState } from 'react'
import dynamic from 'next/dynamic'
import LazyLoad from 'react-lazyload'
import Modal from 'src/components/shared/Modal'
import VideoPlayer from 'src/components/shared/VideoPlayer'
import Img from 'src/components/shared/Img'
import useDeviceDetect from 'src/common/hooks/useDeviceDetect'
import SvgIcon from 'src/components/shared/SvgIcon'
import styles from './index.module.scss'
import classNames from 'classnames'
import CallToAction from 'src/components/shared/CallToActionButton'

const CarouselV2 = dynamic(() =>
  import('src/components/shared/BrandPages/CarouselV2')
)

const Indicator = ({ isMobile, label, value, className = '' }) => (
  <div className={`${isMobile ? 'w-1/3 p-2' : 'w-1/5 p-4'} ${className}`}>
    <div className="pb-3 lg:w-auto lg:pb-0">
      <div className="mx-auto flex w-fit flex-col items-center">
        <div className="pt-2 text-center text-12 text-black-base">{label}</div>
        <div className="text-center text-2xl font-medium text-black-base">
          {value}
        </div>
      </div>
    </div>
  </div>
)

const ClientStoryItem = ({ img, name }) => {
  const { isMobile } = useDeviceDetect()
  const size = isMobile ? 210 : 370

  return (
    <Img
      src={img}
      alt={name}
      width={size}
      height={size}
      imgWidth={size}
      quality={100}
      styles={{ position: 'relative', width: `${size}px`, height: `${size}px` }}
    />
  )
}

const ClientStoryItemMessage = ({
  name,
  totalDebt,
  itemIndex,
  activeIndex,
}) => {
  return (
    <>
      <div
        className={classNames(
          styles['client-story-badge'],
          'px-4 py-1 text-14 lg:text-heading-4'
        )}
      >
        Resolved debt {totalDebt} 🎉
      </div>
      {activeIndex === itemIndex && (
        <div className="my-6 text-[24px] font-[500] leading-[31.2px]">
          <p>{name}</p>
        </div>
      )}
    </>
  )
}

const ClientStories = ({ clientStories, variation }) => {
  const { isMobile } = useDeviceDetect()
  const [playVideo, setPlayVideo] = useState(false)
  const [active, setActive] = useState(0)
  const [videoLink, setVideoLink] = useState(null)

  const handleVideoPLaying = useCallback(() => {
    setPlayVideo((current) => !current)
  }, [setPlayVideo])

  const openVideo = (link) => {
    setVideoLink(link)
    setPlayVideo(true)
  }

  const getSuperScriptNumber = useCallback(
    () =>
      ['variant-2', 'variant-3', 'variant-5', 'variant-6'].includes(variation)
        ? '⁴'
        : '¹',
    [variation]
  )

  return (
    <div className="flex w-full flex-col items-center justify-center p-2 text-center md:p-10">
      <div className="w-content flex w-full flex-col justify-center p-2 text-center md:w-[1281px] md:p-10">
        <p className="text-sm">{clientStories.title}</p>
        <h1 className="my-5 mb-12 text-3xl font-bold">
          {clientStories.subtite}
          <span className="relative">{getSuperScriptNumber()}</span>
        </h1>
        <div className="main-slider-container w-full">
          <LazyLoad>
            <CarouselV2
              gridItems={clientStories.stories}
              ItemComponentCarousel={ClientStoryItem}
              ItemComponentMessage={ClientStoryItemMessage}
              withMessages
              onChangeActive={setActive}
            />
          </LazyLoad>
        </div>
        <div className="mx-auto my-2 px-3 md:my-5 md:max-w-[864px] md:px-10">
          <p
            className={`text-left text-[18px] font-bold leading-[30.6px] md:text-[30px]`}
          >
            {clientStories.stories[active].story}
          </p>
        </div>
        <div className={`flex items-center justify-center divide-x-2 py-10`}>
          <Indicator
            isMobile={isMobile}
            label={clientStories.totalDebt}
            value={clientStories.stories[active].totalDebt}
          />
          <Indicator
            isMobile={isMobile}
            label={clientStories.montlyPayments}
            value={clientStories.stories[active].montlyPayment}
          />
          <Indicator
            isMobile={isMobile}
            label={clientStories.debtsResolved}
            value={clientStories.stories[active].debtsResolved}
          />
        </div>
        <div className="flex items-center justify-center">
          <div
            className="cursor-pointer font-bold text-blue-700"
            onClick={() => openVideo(clientStories.stories[active].videoLink)}
          >
            Watch {clientStories.stories[active].pronoun} story
          </div>
          <SvgIcon
            name="play"
            onClick={() => openVideo(clientStories.stories[active].videoLink)}
            className="ml-2 w-6 cursor-pointer"
          />
        </div>
        <CallToAction
          text="Get your free evaluation"
          title="Get your free evaluation"
          fontClassName="text-lg font-bold leading-snug tracking-normal text-white"
          className="mx-auto my-8 min-w-[333px] rounded-lg py-2.5 md:mx-auto md:w-fit md:min-w-[462px] md:px-8"
          bgColor="bg-blue-base"
          fullWidth
        />
      </div>
      {playVideo ? (
        <Modal
          open={playVideo}
          onClose={handleVideoPLaying}
          className="z-20 flex items-center bg-black-base px-12 lg:h-auto"
          closeButtonClassNames="w-12 h-12 hover:bg-white hover:rounded-[50%] flex items-center justify-center px-0 lg:right-1 lg:top-1"
        >
          <VideoPlayer
            url={videoLink}
            light={false}
            className="h-[300px] w-[100svw] lg:h-[590px] lg:w-[967px]"
          />
        </Modal>
      ) : null}
    </div>
  )
}

export default ClientStories
