import React, { useState } from 'react'
import CallToAction from '../../../shared/CallToActionButton'
import SvgIcon from '../../../shared/SvgIcon'
import { KEY_BENEFITS_SECTION } from './const'
import { useAnalytics } from 'src/common/analytics'
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver'

export const KeyBenefitsSectionItem = ({
  icon,
  title,
  description,
  className = '',
}) => {
  return (
    <div className={`flex w-full flex-row gap-4 md:flex-col ${className}`}>
      <div>
        <SvgIcon name={icon} className="h-[30px] w-[30px] stroke-blue-700" />
      </div>
      <div className="space-y-1 text-gray-555 lg:space-y-4">
        <p className="text-lg font-medium lg:text-2xl">{title}</p>
        <p className="text-sm font-normal lg:text-base">{description}</p>
      </div>
    </div>
  )
}

export const onViewH2Id = 'key-benefits-section'

const KeyBenefitsSection = ({ variation }) => {
  const { title, items, cta } = KEY_BENEFITS_SECTION
  const [showAll, setshowAll] = useState(false)
  const { track } = useAnalytics()

  const { ref } = useIntersectionObserver({
    threshold: 0.5,
    onChange: (isIntersecting) => {
      if (!isIntersecting) return
      track(
        { additional_info: { section: onViewH2Id }, event_type: 'track' },
        {},
        'section_view'
      )
    },
    freezeOnceVisible: true,
  })

  const handleCollapsed = () => {
    setshowAll((state) => !state)
  }

  return (
    <div className="flex flex-col gap-y-6 bg-blue-135 px-[21px] py-12 md:gap-y-12 md:py-16">
      <h2
        ref={ref}
        className="text-center text-2xl font-bold text-gray-555 lg:text-4xl lg:font-medium"
      >
        {title}
      </h2>
      <div className="flex flex-wrap justify-center gap-x-5 gap-y-8 text-left md:mx-auto md:grid md:max-w-[1062px] md:grid-cols-2 md:gap-x-16 lg:grid-cols-3">
        {items.map((item, i) => (
          <KeyBenefitsSectionItem
            key={item.id + '_mobile'}
            title={item.title}
            description={item.description}
            icon={item.icon}
            className={i >= 3 && !showAll ? 'sr-only' : 'md:max-w-[292px]'}
          />
        ))}
      </div>
      <button
        onClick={handleCollapsed}
        className="mx-auto my-3 flex items-center gap-x-2 font-bold text-blue-600"
      >
        <span>{showAll ? 'Show less' : 'Show all'}</span>
        <SvgIcon
          name="chevronThinLeft"
          className={`h-4 w-4 stroke-blue-600 stroke-2 ${
            showAll ? 'rotate-90' : '-rotate-90'
          }`}
        />
      </button>
      <CallToAction
        title={title}
        fontClassName="text-lg font-bold leading-snug tracking-normal text-white"
        className="mx-auto rounded-lg md:mx-auto md:w-fit md:px-8"
        text={cta.text}
        bgColor="bg-blue-600"
        fullWidth
      />
    </div>
  )
}

KeyBenefitsSection.defaultProps = {}

KeyBenefitsSection.propTypes = {}

export default KeyBenefitsSection
